import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Progetto from '../components/Progetto/Progetto';

import MainLayout from '../layaouts/MainLayout/MainLayout';

function ProgettoPage({ pageContext }) {
  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <Progetto data={data} />
    </MainLayout>
  );
}

export default injectIntl(ProgettoPage);
