import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import arrR from '../../assets/icons/arrR.svg'
import './style.css'
import Button from '../Forms/Button/Button'
import SliderBeato from '../IlBeato/SliderBeato/SliderBeato'
import IconPromoters from '../../assets/icons/single_project_promoters.svg'
import albero from '../../assets/icons/albero_white.svg'
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout'
import Promotori from '../Promotori/Promotori'
import MetaTags from '../Header/MetaTags'

function Progetto({ data }) {
  const breakpoints = useBreakpoint()
  const intl = useIntl()
  if (!data[intl.locale]) {
    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {`No data for current language ( ${intl.locale} )`}
      </div>
    )
  }
  const {
    projectPreview,
    descriptionParagraph,
    videoBlock,
    firstImageSlider,
    promotorClaimers,
  } = data[intl.locale].acf
  const { yoast_head_json } = data[intl.locale]
  const { categories } = data[intl.locale]
  let renderVideo
  !videoBlock?.videoUrl ? (renderVideo = false) : (renderVideo = true)

  const [collapse, setCollapse] = useState(true)
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' })

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div className="singleProjectPage">
        {projectPreview.projectImage && (
          <div className="singleProjectPage_heroIMG">
            {projectPreview && (
              <img
                src={projectPreview.projectImage}
                alt="projectIMG"
                style={{
                  position: 'absolute',
                  objectFit: 'cover',
                  maxHeight: 446,
                }}
              />
            )}
            {data[intl.locale].title.rendered && (
              <div
                style={{
                  position: 'absolute',
                  marginLeft: !breakpoints.md ? 50 : 0,
                }}
              >
                <div className="singleProjectPage_heroIMG_subtitle">
                  {categories && categories.map(cat => `${cat.name}  `)}
                </div>
                <h1 className="singleProjectPage_heroIMG_title">
                  {data[intl.locale].title && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data[intl.locale].title.rendered,
                      }}
                    />
                  )}
                </h1>
              </div>
            )}
          </div>
        )}
        <div className="singleProjectPage_body">
          {categories.length > 0 && (
            <div className="singleProjectPage_projectCategory">
              {categories.length > 0 && (
                <div className="singleProjectPage_projectCategoriesIcons">
                  {categories.map(cat => {
                    if (!cat.acf['Category Icon'].categoryIcon) return null
                    return (
                      <div
                        style={{
                          backgroundColor:
                            cat.acf['Category Icon'].categoryColor,
                          width: !breakpoints.md ? 70 : 38,
                          height: !breakpoints.md ? 70 : 38,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={cat.acf['Category Icon'].categoryIcon}
                          alt="catIcon"
                          style={{ width: !breakpoints.md ? 40 : 20 }}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
              {categories.length > 0 && (
                <div className="singleProjectPage_projectCategories">
                  {categories.map((cat, index) => {
                    if (!cat.name) return null
                    return (
                      <div style={{ marginRight: 5 }}>
                        {cat.name}
                        {index !== categories.length - 1 && ','}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}
          {descriptionParagraph.paragraphTitle &&
            descriptionParagraph.contentVisibile && (
              <div className="singleProjectPage_descParagraph">
                <div className="collapsableTextBox">
                  <div className="titleBox">
                    {descriptionParagraph.paragraphTitle}
                  </div>
                  {descriptionParagraph.contentVisibile
                    && descriptionParagraph.contentHidden && (
                      <div className="collapsable">
                        <div className="collapsableText">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: collapse
                                ? descriptionParagraph.contentVisibile
                                : descriptionParagraph.contentVisibile.concat(
                                    descriptionParagraph.contentHidden
                                  ),
                            }}
                          />
                        </div>

                        {descriptionParagraph.contentHidden && (
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Button
                              handleClick={() => {
                                setCollapse(!collapse)
                              }}
                              id="collapseBtn"
                              label={btnLabel}
                              style={{
                                borderRadius: 0,
                                textTransform: 'uppercase',
                                color: 'black',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '21px',
                              }}
                            >
                              {collapse ? (
                                <img
                                  alt="accordionIMG"
                                  src={arrR}
                                  style={{
                                    marginLeft: 6,
                                    width: '16px',
                                    height: '16px',
                                  }}
                                />
                              ) : (
                                <img
                                  alt="accordionIMG"
                                  src={arrR}
                                  style={{
                                    marginLeft: 6,
                                    width: '16px',
                                    height: '16px',
                                    transform: 'rotate(-180deg)',
                                  }}
                                />
                              )}
                            </Button>
                          </span>
                        )}
                      </div>
                  )}
                </div>
              </div>
          )}
          {(videoBlock.videoUrl ||
            firstImageSlider.firstImageSlider[0]?.image) && (
            <div className="singleProjectPage_media">
              {videoBlock.videoUrl ? (
                <div
                  id={videoBlock.videoPosition}
                  style={{ width: !breakpoints.md ? 735 : 325, height: !breakpoints.md ? 440 : 185 }}
                  dangerouslySetInnerHTML={{ __html: videoBlock.videoUrl }}
                />
              ) : firstImageSlider.firstImageSlider.length > 0 ? (
                <SliderBeato data={firstImageSlider.firstImageSlider} />
              ) : null}
            </div>
          )}
        </div>

        {promotorClaimers && (
          <BlockLayout
            isTitleH3
            style={{ backgroundImage: `url(${albero})` }}
            icon={IconPromoters}
            title={intl.formatMessage({
              id: 'single_project__promotori_title',
            })}
            bgColor="#f2f5f7"
          >
            <Promotori promoters={promotorClaimers} />
          </BlockLayout>
        )}
      </div>
    </>
  )
}

export default Progetto
