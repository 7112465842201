import React, { useState, useRef } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';
import arrR from '../../../assets/icons/arrR.svg';

export default function SliderBeato({ data, goTo, id }) {
  const breakpoints = useBreakpoint();
  const [slideIndex, setSlideIndex] = useState(1);
  const sliderRef = useRef(null);

  const nextSlide = () => {
    if (slideIndex !== data.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === data.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(data.length);
    }
  };

  // const moveDot = (index) => {
  //   setSlideIndex(index);
  // };

  if (breakpoints.md) {
    return (
      <div
        id={id}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '60px auto',
        }}
      >
        <div className="container-slider-beato">
          {data
            && data.map((obj, index) => {
              if (!obj.image) return null;
              return (
                <div ref={sliderRef}>
                  <div
                    className={
                      slideIndex === index + 1 ? 'slideBeato active-anim' : 'slideBeato'
                    }
                  >
                    <img
                      alt="sliderIMG"
                      src={obj.image}
                      style={{ position: 'absolute' }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        {data.length >= 2 && (
          <div className="slider-beato-arrows-container">
            <img
              alt="accordionIMG"
              src={arrR}
              className="arrowsSlider"
              onClick={prevSlide}
              style={{ transform: 'rotate(-180deg)', cursor: 'pointer' }}
            />
            <img
              alt="accordionIMG"
              src={arrR}
              className="arrowsSlider"
              onClick={nextSlide}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    );
  }
  if (!breakpoints.md) {
    return (
      <div
        id={id}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {data.length >= 2 && (
          <img
            alt="accordionIMG"
            src={arrR}
            className="arrowsSlider"
            onClick={prevSlide}
            style={{ transform: 'rotate(-180deg)' }}
          />
        )}
        <div className="container-slider-beato">
          {data
            && data.map((obj, index) => {
              if (!obj.image) return null;
              return (
                <div ref={sliderRef}>
                  <div
                    className={
                      slideIndex === index + 1 ? 'slideBeato active-anim' : 'slideBeato'
                    }
                  >
                    <img
                      alt="sliderIMG"
                      src={obj.image}
                      style={{ position: 'absolute' }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        {data.length >= 2 && (
          <img
            alt="accordionIMG"
            src={arrR}
            className="arrowsSlider"
            onClick={nextSlide}
          />
        )}
      </div>
    );
  }
}
